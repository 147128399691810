import { Link } from "raviger";

import { cn } from "@/lib/utils";

import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

import { FacilityModel } from "@/components/Facility/models";

import { FeatureBadge, FeatureId } from "../Utils";

interface Props {
  facility: FacilityModel;
  className?: string;
}

export function FacilityCard({ facility, className }: Props) {
  return (
    <Card className={cn("overflow-hidden bg-white", className)}>
      <div className="flex flex-col">
        <div className="p-6">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="h-32 w-32 shrink-0 overflow-hidden rounded-lg">
              <img
                src={
                  facility.read_cover_image_url ||
                  "/images/default-facility.png"
                }
                alt={facility.name}
                className="h-full w-full object-cover"
              />
            </div>

            <div className="flex grow flex-col min-w-0">
              <h3 className="truncate text-xl font-semibold">
                {facility.name}
              </h3>
              <p className="text-sm text-muted-foreground truncate">
                {[
                  facility.address,
                  facility.local_body_object?.name,
                  facility.district_object?.name,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </p>

              <div className="mt-2 flex flex-wrap gap-2">
                {facility.features?.map((featureId) => (
                  <FeatureBadge
                    key={featureId}
                    featureId={featureId as FeatureId}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-auto border-t border-gray-100 bg-gray-50 p-4">
          <div className="flex justify-end">
            <Button variant="outline" asChild>
              <Link href={`/facility/${facility.id}`}>View Facility</Link>
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
