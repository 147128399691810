// Use type assertion for the static import


import type { PluginManifest } from "./pluginTypes";

const pluginMap: PluginManifest[] = [
  
];

export { pluginMap };
